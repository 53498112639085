<template>
    <div class="settings--container" :class="{'scrolled' : $route.name === 'SettingsProfileView'}">
        <div class="nav-bar--container" v-if="isMobile && $route.name !== 'SettingsMainListView'" >
            <div class="nav--item" @click="back">
                <img class="back-img__button" src="../../../assets/icons/back.svg">
            </div>
            <div class="nav--item">
                <p class="text__title">{{$route.meta.title}}</p>
            </div>
            <div class="nav--item nav__transparent"></div>
        </div>
        <div class="router--container">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SettingsMainView",
        computed:{
            isMobile(){
                return screen.width < 992
            }
        },
        methods:{
            back(){
                this.$router.push({name : 'SettingsMainListView'})
            }

        }
    }
</script>

<style scoped lang="scss">
    .settings--container{
        width: 100%;
        height: 100%;
        background-color: #f8f8f8;
        display: flex;
        flex-direction: column;


        @include for-mobile-devices{
            &.scrolled{
                height: calc(100% + 120px);
            }
        }


        .nav-bar--container{
            width: 100%;
            height: 20px;
            display: flex;
            justify-content: space-between;
            margin-top: 14px;
            align-items: center;

            .nav--item{
                height: 20px;
                .back-img__button{
                    width: 20px;
                    height: 20px;
                }


                .text__title{
                    font-family: Roboto, "sans-serif";
                    font-size: 17px;
                    letter-spacing: 0;
                    color: #222222;
                    line-height: 20px;
                }

            }

            .nav--item:first-child{
                margin-left: 20px;
            }

            .nav--item:last-child{
                margin-right: 20px;
            }

        }
    }
</style>
